// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-approach-tsx": () => import("../src/pages/en/approach.tsx" /* webpackChunkName: "component---src-pages-en-approach-tsx" */),
  "component---src-pages-en-approach-capability-transfer-tsx": () => import("../src/pages/en/approach/capability-transfer.tsx" /* webpackChunkName: "component---src-pages-en-approach-capability-transfer-tsx" */),
  "component---src-pages-en-approach-continuous-development-tsx": () => import("../src/pages/en/approach/continuous-development.tsx" /* webpackChunkName: "component---src-pages-en-approach-continuous-development-tsx" */),
  "component---src-pages-en-approach-kick-off-tsx": () => import("../src/pages/en/approach/kick-off.tsx" /* webpackChunkName: "component---src-pages-en-approach-kick-off-tsx" */),
  "component---src-pages-en-approach-product-realisation-tsx": () => import("../src/pages/en/approach/product-realisation.tsx" /* webpackChunkName: "component---src-pages-en-approach-product-realisation-tsx" */),
  "component---src-pages-en-approach-prototyping-tsx": () => import("../src/pages/en/approach/prototyping.tsx" /* webpackChunkName: "component---src-pages-en-approach-prototyping-tsx" */),
  "component---src-pages-en-approach-service-tsx": () => import("../src/pages/en/approach/service.tsx" /* webpackChunkName: "component---src-pages-en-approach-service-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-index-tsx": () => import("../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-portfolio-tsx": () => import("../src/pages/en/portfolio.tsx" /* webpackChunkName: "component---src-pages-en-portfolio-tsx" */),
  "component---src-pages-en-portfolio-saas-product-keeping-tsx": () => import("../src/pages/en/portfolio/saas-product-keeping.tsx" /* webpackChunkName: "component---src-pages-en-portfolio-saas-product-keeping-tsx" */),
  "component---src-pages-en-portfolio-translation-platform-fairlingo-tsx": () => import("../src/pages/en/portfolio/translation-platform-fairlingo.tsx" /* webpackChunkName: "component---src-pages-en-portfolio-translation-platform-fairlingo-tsx" */),
  "component---src-pages-en-portfolio-valuation-platform-taxapi-tsx": () => import("../src/pages/en/portfolio/valuation-platform-taxapi.tsx" /* webpackChunkName: "component---src-pages-en-portfolio-valuation-platform-taxapi-tsx" */),
  "component---src-pages-en-terms-and-conditions-explanation-tsx": () => import("../src/pages/en/terms-and-conditions/explanation.tsx" /* webpackChunkName: "component---src-pages-en-terms-and-conditions-explanation-tsx" */),
  "component---src-pages-en-terms-and-conditions-index-tsx": () => import("../src/pages/en/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-en-terms-and-conditions-index-tsx" */),
  "component---src-pages-en-terms-and-conditions-privacy-tsx": () => import("../src/pages/en/terms-and-conditions/privacy.tsx" /* webpackChunkName: "component---src-pages-en-terms-and-conditions-privacy-tsx" */),
  "component---src-pages-en-terms-and-conditions-security-tsx": () => import("../src/pages/en/terms-and-conditions/security.tsx" /* webpackChunkName: "component---src-pages-en-terms-and-conditions-security-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nl-contact-tsx": () => import("../src/pages/nl/contact.tsx" /* webpackChunkName: "component---src-pages-nl-contact-tsx" */),
  "component---src-pages-nl-portfolio-tsx": () => import("../src/pages/nl/portfolio.tsx" /* webpackChunkName: "component---src-pages-nl-portfolio-tsx" */),
  "component---src-pages-nl-portfolio-saas-product-keeping-tsx": () => import("../src/pages/nl/portfolio/saas-product-keeping.tsx" /* webpackChunkName: "component---src-pages-nl-portfolio-saas-product-keeping-tsx" */),
  "component---src-pages-nl-portfolio-taxatieplatform-taxapi-tsx": () => import("../src/pages/nl/portfolio/taxatieplatform-taxapi.tsx" /* webpackChunkName: "component---src-pages-nl-portfolio-taxatieplatform-taxapi-tsx" */),
  "component---src-pages-nl-portfolio-vertaalplatform-fairlingo-tsx": () => import("../src/pages/nl/portfolio/vertaalplatform-fairlingo.tsx" /* webpackChunkName: "component---src-pages-nl-portfolio-vertaalplatform-fairlingo-tsx" */),
  "component---src-pages-nl-voorwaarden-index-tsx": () => import("../src/pages/nl/voorwaarden/index.tsx" /* webpackChunkName: "component---src-pages-nl-voorwaarden-index-tsx" */),
  "component---src-pages-nl-voorwaarden-privacy-tsx": () => import("../src/pages/nl/voorwaarden/privacy.tsx" /* webpackChunkName: "component---src-pages-nl-voorwaarden-privacy-tsx" */),
  "component---src-pages-nl-voorwaarden-uitgebreide-toelichting-tsx": () => import("../src/pages/nl/voorwaarden/uitgebreide-toelichting.tsx" /* webpackChunkName: "component---src-pages-nl-voorwaarden-uitgebreide-toelichting-tsx" */),
  "component---src-pages-nl-voorwaarden-veiligheid-tsx": () => import("../src/pages/nl/voorwaarden/veiligheid.tsx" /* webpackChunkName: "component---src-pages-nl-voorwaarden-veiligheid-tsx" */),
  "component---src-pages-nl-werkwijze-tsx": () => import("../src/pages/nl/werkwijze.tsx" /* webpackChunkName: "component---src-pages-nl-werkwijze-tsx" */),
  "component---src-pages-nl-werkwijze-capability-transfer-tsx": () => import("../src/pages/nl/werkwijze/capability-transfer.tsx" /* webpackChunkName: "component---src-pages-nl-werkwijze-capability-transfer-tsx" */),
  "component---src-pages-nl-werkwijze-continue-doorontwikkeling-tsx": () => import("../src/pages/nl/werkwijze/continue-doorontwikkeling.tsx" /* webpackChunkName: "component---src-pages-nl-werkwijze-continue-doorontwikkeling-tsx" */),
  "component---src-pages-nl-werkwijze-kick-off-tsx": () => import("../src/pages/nl/werkwijze/kick-off.tsx" /* webpackChunkName: "component---src-pages-nl-werkwijze-kick-off-tsx" */),
  "component---src-pages-nl-werkwijze-productrealisatie-tsx": () => import("../src/pages/nl/werkwijze/productrealisatie.tsx" /* webpackChunkName: "component---src-pages-nl-werkwijze-productrealisatie-tsx" */),
  "component---src-pages-nl-werkwijze-prototyping-tsx": () => import("../src/pages/nl/werkwijze/prototyping.tsx" /* webpackChunkName: "component---src-pages-nl-werkwijze-prototyping-tsx" */),
  "component---src-pages-nl-werkwijze-service-tsx": () => import("../src/pages/nl/werkwijze/service.tsx" /* webpackChunkName: "component---src-pages-nl-werkwijze-service-tsx" */)
}

